import logo from './logo.svg';
import './App.css';
import ParticlesComponent from './components/particles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function App() {
  const links = {
    linkedin: { href: "https://www.linkedin.com/in/ashkan-aleshams/", title: 'LinkedIn' },
    github: { href: "https://github.com/AshkanAleshams/", title: 'Projects on Github' },
    resume: { href: "/Ashkan_Aleshams_Resume.pdf", title: 'Ashkan_Aleshams_Resume.pdf' },
    email: { href: "mailto:ashkan@aleshams.com", title: 'ashkan@aleshams.com' }
  };
  return (
    <div className="App">
      <div className='container'>


        <div className='name-container'>
          <h1 className='name'>Ashkan Aleshams</h1>
        </div>
        <div className='title-container'>
          <p className='title'>Full-stack Software Developer</p>
        </div>
        <div className='links-container'>
          <a href={links.linkedin.href} title={links.linkedin.title} target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
          <a href={links.github.href} title={links.github.title} target="_blank" rel="noopener noreferrer">
            Projects
          </a>
          <a href={links.resume.href} title={links.resume.title} target="_blank" rel="noopener noreferrer" download>
            Resume
          </a>
          <a href={links.email.href} title={links.email.title} target="_blank" rel="noopener noreferrer">
            Email
          </a>
        </div>
      </div>

      <ParticlesComponent id="particles" />

    </div >
  );
}

export default App;
